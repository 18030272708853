import React from 'react'
import { RadioGroupField } from '@toasttab/buffet-pui-forms'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useFormikContext } from 'formik'
import { ServiceDefinition } from '../types/services'

interface IDPLinkProp {
  text: string
  link: string
}

const IDPLink = ({text, link}: IDPLinkProp) => {
  return (
    <Button 
    as='a'
    href={link}
    variant='link'
    >
      {text}
    </Button>
  )
}

const IDPLinks: Array<IDPLinkProp> = [
  {
    text: "REST (G2) Service",
    link: "https://idp.eng.toasttab.com/create/templates/default/rest-service-template",
  },
  {
    text: "Child Single Page App (SPA)",
    link: "https://idp.eng.toasttab.com/create/templates/default/child-spa-template",
  },
  {
    text: "Widget Single Page App (SPA)",
    link: "https://idp.eng.toasttab.com/create/templates/default/widget-spa-template",
  },
  {
    text: "Layout Single Page App (SPA)",
    link: "https://idp.eng.toasttab.com/create/templates/default/layout-spa-template",
  },
  {
    text: "Camel Service",
    link: "https://idp.eng.toasttab.com/create/templates/default/camel-template",
  },
  {
    text: "gRPC Service",
    link: "https://idp.eng.toasttab.com/create/templates/default/grpc-service-template",
  },
  {
    text: "Shared Library",
    link: "https://idp.eng.toasttab.com/create/templates/default/shared-library-template",
  },
  {
    text: "Empty Repository",
    link: "https://idp.eng.toasttab.com/create/templates/default/empty-repo-template",
  },
]

export const ServiceSelector = () => {
  return (
    <div>
      <RadioGroupField
        label='Service type'
        name='serviceType'
        testId='service-type'
        options={[
          { value: 'BFF', label: 'Backend For Frontend (BFF)' },
          { value: 'SCHEDULED_TASK', label: 'Scheduled Task (new repo)' },
        ]}
      />
      <div>
        <ul>
          {
            IDPLinks.map((props) => ( 
              <li key={props.text}>
                <IDPLink {...props}/>
              </li>
            ))
          }
        </ul>
      </div>
      <div>
        For other service types, please refer to&nbsp;
            <a
              className='inline-link'
              href='https://docs.tdp.eng.toasttab.com/html/docsrc/how-to-create-a-service.html#how-to-create-a-service-in-tdp'
              target='_blank'
              rel='noreferrer'
            >
              this doc.
            </a>
      </div>
    </div>
  )
}

export const DBSelector = () => {
  const formik = useFormikContext<ServiceDefinition>()
  let serviceSpecificOptions
  switch (formik.values.serviceType) {
    case 'CAMEL':
      serviceSpecificOptions = [
        { value: 'NONE', label: 'None' },
        { value: 'DYNAMODB', label: 'NoSQL (Dynamo)' }
      ]
      break
    default:
      serviceSpecificOptions = [
        { value: 'NONE', label: 'None' },
        { value: 'DYNAMODB', label: 'NoSQL (Dynamo)' },
        { value: 'POSTGRESQL', label: 'PostgreSQL' }
      ]
  }

  return (
    <RadioGroupField
      label='Database type'
      name='serviceOptions.dbType'
      testId='database-type'
      options={serviceSpecificOptions}
    />
  )
}

export const PulsarOption = () => {
  const formik = useFormikContext<ServiceDefinition>()
  let serviceSpecificOptions = [
    { value: 'NONE', label: 'None' },
    { value: 'ENABLED', label: 'Enable Pulsar' }
  ]
  return (
    <RadioGroupField
      label='Pulsar'
      name='serviceOptions.pulsar'
      testId='pulsar-option'
      options={serviceSpecificOptions}
    />
  )

}
